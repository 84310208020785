<template>
  <div class="flex justify-center">
    <div style="max-width: 314px">
      <div class="flex justify-center my-4">
        <img src="../../assets/logo.png" height="32"  alt="logo"/>
      </div>
      <div>
        <div class="text-center"><h4>Reset password</h4></div>
        <form>
          <Input
              v-model="newPassword"
              label="New password"
              name="newPassword"
              type="password"
              :disabled="loading"
              ref="newPassword"
          />
          <Input
              v-model="confirmNewPassword"
              label="Confirm new password"
              name="confirmNewPassword"
              type="password"
              :disabled="loading"
          />
          <div class="flex wrap align-center mt-4">
            <div class="xs12">
              <Button
                variant="smooth primary"
                :disabled="loading || newPassword !== confirmNewPassword || !newPassword"
                class="mb-2 width100"
                @click="updatePassword"
                :loading="loading"
              >
                Update password
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import Button from '../common/Button.vue'
  import Input from '../common/Input.vue'

  export default {
    name: 'ResetPasswordChange',
    components: { Button, Input },
    data: () => ({
      loading: false,
      newPassword: "",
      confirmNewPassword: ""
    }),
    mounted() {
      this.$refs.newPassword.focus()
    },
    methods: {
      async updatePassword() {
        this.loading = true
        await this.$store.dispatch("resetPasswordChangePassword", {
          newPassword: this.newPassword,
          token: this.$route.params.token
        })
        this.loading = false
        this.$router.push({ name: "signIn" })
      }
    }
  }
</script>

<style scoped></style>
