<template>
  <div>
    <Input v-model="oldPassword" label="Old password" type="password"></Input>
    <Input class="mt-3" v-model="newPassword" label="New password" type="password"></Input>
    <Input class="mt-3" v-model="confirmNewPassword" label="Confirm new password" type="password"></Input>
    <div class="flex justify-end mt-3">
      <Button
          @click="updatePassword"
          :disabled="loading || !oldPassword || !newPassword || newPassword !== confirmNewPassword"
          :loading="loading"
          variant="primary smooth"
      >
        Update password
      </Button>
    </div>
  </div>
</template>
<script>

import Input from "@/components/common/Input.vue"
import Button from "@/components/common/Button.vue"

export default {
  name: 'ChangePassword',
  props: [],
  components: { Input, Button },
  data: () => ({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    loading: false
  }),
  mounted() {
  },
  computed: {
  },
  methods: {
    async updatePassword() {
      this.loading = true
      console.log({
          newPassword: this.newPassword,
          oldPassword: this.oldPassword
        })
      await this.$store.dispatch("changePassword", {
        newPassword: this.newPassword,
        oldPassword: this.oldPassword
      })
      this.loading = false
    }
  }
}
</script>