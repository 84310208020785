<template>
  <div class="flex justify-center">
    <div style="max-width: 414px">
      <div class="flex justify-center my-4">
        <img src="../../assets/logo.png" height="32" />
      </div>
      <div>
        <div class="text-center"><h4>Registration confirmed</h4></div>
      </div>
    </div>
  </div>
</template>

<script>
  import { sleep } from "@/utils"

  export default {
    name: 'ConfirmRegistration',
    components: {},
    data: () => ({
      loading: false,
    }),
    watch: {},
    async mounted() {
      const token = this.$route.params.token
      console.log("token", token)
      if(token) {
        await this.$store.dispatch("confirmRegistration", token)
      }
      await sleep(1000)
      this.$router.push({ name: "signIn" })
    },
    methods: {
    }
  }
</script>

<style scoped></style>
