<template>
  <div class="flex justify-center">
    <div style="max-width: 414px">
      <div class="flex justify-center my-4">
        <img src="../../assets/logo.png" height="32" />
      </div>
      <div>
        <div class="text-center"><h4>Check your e-mail to confirm registration</h4></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ConfirmRegistrationCheckEmail',
    components: {},
    data: () => ({
      loading: false,
    }),
    watch: {},
    async mounted() {
    },
    methods: {
    }
  }
</script>

<style scoped></style>
