<template>
  <div class="flex justify-center">
    <div style="max-width: 314px">
      <div class="flex justify-center my-4">
        <img src="../../assets/logo.png" height="32" />
      </div>
      <div class="text-center"><h3>Sign in</h3></div>
      <form>
        <Input
          :value="email"
          @input="email = $event"
          label="E-mail"
          name="email"
          :disabled="loading"
        />
        <div class="my-4 position-relative">
          <Input
            :value="password"
            @input="password = $event"
            label="Password"
            name="password"
            type="password"
            :disabled="loading"
            :error="error"
          />
          <RouterLink :to="{ name: 'resetpassword' }" class="forgot-password">
            Forgot password?
          </RouterLink>
        </div>
        <div class="flex wrap align-center">
          <div class="xs12">
            <Button
              variant="smooth primary"
              :disabled="loading"
              class="mb-2 width100"
              @click="signIn"
              :loading="loading"
            >
              Sign in
            </Button>
          </div>
          <div class="xs12 text-center pt-5">
            Don't have an account?
            <router-link class="ml-3" to="signup"
              >Create an account</router-link
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import Input from '../common/Input.vue'
  import Button from '../common/Button.vue'

  export default {
    name: 'SignIn',
    components: { Input, Button },
    data: () => ({
      email: '',
      password: '',
      loading: false,
      error: ''
    }),
    methods: {
      async signIn() {
        this.loading = true

        await this.$store.dispatch('signIn', {
          email: this.email,
          password: this.password
        })

        this.loading = false

        this.$router.push({ name: 'home' })
      }
    }
  }
</script>

<style scoped>
  .forgot-password {
    position: absolute;
    top: -2px;
    right: 0;
    font-size: 12px;
  }
</style>
