<template>
  <div>
    <div class="glass-panel pa-3 ma-3">Marketing</div>
    <div class="glass-panel pa-3 ma-3">
      <RouterLink :to="{ name: 'signIn' }">Sign in</RouterLink>
    </div>
    <div class="glass-panel pa-3 ma-3">
      <RouterLink :to="{ name: 'signUp' }">SignUp</RouterLink>
    </div>
    <div class="glass-panel pa-3 ma-3">Calendar!</div>
    <div class="glass-panel pa-3 ma-3">Tasks!</div>
    <div class="glass-panel pa-3 ma-3">Export reports!</div>
  </div>
</template>

<script>
  export default {
    name: 'Marketing',
    components: {},
    data: () => ({}),
    watch: {},
    mounted() {},
    computed: {},
    methods: {}
  }
</script>

<style scoped></style>
