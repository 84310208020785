<template>
  <div>
    <div class="flex wrap" v-if="!paymentMethod">
      <div class="xs12">
        <Input
            label="Card number"
            :value="newPaymentMethod.number"
            @input="newPaymentMethod.number = $event"
        ></Input>
      </div>
      <div class="xs12 flex mt-3">
        <div class="4">
          <Input
              label="Exp month"
              :value="newPaymentMethod.expMonth"
              @input="newPaymentMethod.expMonth = $event"
              class="mr-3"
          ></Input>
        </div>
        <div class="4">
          <Input
              label="Exp year"
              :value="newPaymentMethod.expYear"
              @input="newPaymentMethod.expYear = $event"
              class="mr-3"
          ></Input>
        </div>
        <div class="4">
          <Input
              label="CVC"
              :value="newPaymentMethod.cvc"
              @input="newPaymentMethod.cvc = $event"
          ></Input>
        </div>
      </div>
    </div>
    <VisaCard v-else :value="{
      brand: paymentMethod.brand.toUpperCase(),
      number: '**** **** **** ' + paymentMethod.last4,
      expMonth: paymentMethod.expMonth,
      expYear: paymentMethod.expYear,
      country: paymentMethod.country
    }">
    </VisaCard>
    <Button :disabled="loading" :loading="loading" v-if="paymentMethod" variant="text" class="mt-4 error-text" @click="deletePaymentMethodDialog.dialog = true">
      Delete payment method
    </Button>
    <Dialog
        class="black-text"
        :value="deletePaymentMethodDialog.dialog"
        @close="
        () => {
          deletePaymentMethodDialog.dialog = false
        }
      "
        :persistent="deletePaymentMethodDialog.loading"
    >
      <DataForm
          type="yesno"
          :value="{}"
          :loading="deletePaymentMethodDialog.loading"
          :disabled="deletePaymentMethodDialog.loading"
          :allowCancel="true"
          @cancel="deletePaymentMethodDialog.dialog = false"
          @update="confirmDeletePaymentMethod($event)"
          title="Are you sure you want to delete the payment method?"
      ></DataForm>
    </Dialog>
  </div>
</template>
<script>
import Input from "@/components/common/Input.vue"
import Button from "@/components/common/Button.vue"
import VisaCard from "@/components/common/VisaCard.vue";
import Dialog from "@/components/common/Dialog.vue";
import DataForm from "@/components/common/DataForm.vue";

export default {
  name: 'PaymentInfo',
  props: ['value', 'loading'],
  components: { DataForm, Dialog, VisaCard, Input, Button },
  data: () => ({
    newPaymentMethod: {
      number: "4242424242424242",
      expMonth: "12",
      expYear: "34",
      cvc: "567"
    },
    saveLoading: false,
    deletePaymentMethodDialog: {
      dialog: false,
      loading: false
    }
  }),
  mounted() {
    if(!this.value) {
      this.$emit("update:modelValue", this.newPaymentMethod)
    } else {
      this.newPaymentMethod = this.value
    }
  },
  watch: {
    newPaymentMethod: {
      deep: true,
      handler(val) {
        this.$emit("input", val)
      }
    }
  },
  computed: {
    paymentMethod() {
      return this.$store.getters.paymentMethod
    }
  },
  methods: {
    async confirmDeletePaymentMethod(val) {
      console.log("confirmDeletePaymentMethod", val)
      this.deletePaymentMethodDialog.loading = true
      await this.$store.dispatch("removePaymentMethod")
      this.deletePaymentMethodDialog.dialog = false
      this.deletePaymentMethodDialog.loading = false
      this.$emit("update:modelValue", this.newPaymentMethod)
      this.$emit("delete")
    }
  }
}
</script>