<template>
  <div>
    <div class="pa-3 ma-3 bottom-separator">
      <h5>Account</h5>
      <div>E-mail: {{ user.email }}</div>
    </div>
    <div class="pa-3 ma-3 bottom-separator">
      <h5>Subscription info</h5>
      <SubscriptionInfo></SubscriptionInfo>
    </div>
    <div v-if="canPasswordChange" class="pa-3 ma-3">
      <h5>Change password</h5>
      <ChangePassword></ChangePassword>
    </div>
  </div>
</template>

<script>

  import SubscriptionInfo from "@/components/modules/SubscriptionInfo.vue"
  import ChangePassword from "@/components/modules/ChangePassword.vue"

  export default {
    name: 'Settings',
    components: { ChangePassword, SubscriptionInfo },
    data: () => ({
      canPasswordChange: true
    }),
    watch: {},
    mounted() {},
    computed: {
      user() {
        return this.$store.getters.user
      }
    },
    methods: {
    }
  }
</script>

<style scoped>
.bottom-separator {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  padding-bottom: 20px;
}

</style>
