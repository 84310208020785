<template>
  <div class="flex mt-3">
    <div v-for="product in products" :key="product.id" class="position-relative flex">
      <input type="radio" :id="product.id" name="product" :checked="selectedProductId === product.id" @change="selectedProductId = product.id" />
      <label :for="product.id" class="pa-4 ma-2 product" :class="{'isActive' : activeProductId === product.id, 'isNext': nextProductId ===
      product.id &&
      product.id !==
      activeProductId }">
        <div v-if="product.id === activeProductId" class="tag success white-text">Active</div>
        <div class="tag warning" v-else-if="nextProductId === product.id">Next</div>
        <h5 class="text-center mt-4">{{ product.name }}</h5>
        <div class="price" v-if="product.price">{{ formatMoney(product.price / 100) }} NOK</div>
        <div class="price" v-else>FREE</div>
        <div class="mx-3" v-if="product.description" v-html="product.description"></div>
      </label>
    </div>
  </div>
  <TransitionExpandY>
    <div v-if="selectedProductId && !selectedProduct?.name.startsWith('Free')">
      <PaymentInfo :loading="deleteLoading" v-model="newPaymentMethod" class="mt-4" @delete="paymentMethodDeleted"></PaymentInfo>
    </div>
  </TransitionExpandY>
  <div class="mt-4 flex">
    <div class="xs12"></div>
    <Button
        :loading="saveLoading"
        :disabled="saveLoading || deleteLoading"
        @click="saveSubscriptionInfo"
        variant="primary smooth"
    >Update subscription
    </Button>
  </div>
</template>
<script>
import Button from "@/components/common/Button.vue"
import PaymentInfo from "@/components/modules/PaymentInfo.vue"
import TransitionExpandY from "@/components/unused/TransitionExpandY.vue"
import { formatMoney } from "@/utils";

export default {
  name: 'SubscriptionInfo',
  components: { TransitionExpandY, PaymentInfo, Button },
  data: () => ({
    selectedProductId: null,
    saveLoading: false,
    newPaymentMethod: null,
    deleteLoading: false
  }),
  watch: {
    newPaymentMethod: {
      deep: true,
      handler(val) {
        console.log("CARD", val)
        // TODO: If not free and card is valid, show save button
      }
    }
  },
  mounted() {
    this.selectedProductId = this.$store.getters.nextProductId || this.products.find(p => p.name.startsWith("Free")).id
  },
  computed: {
    paymentMethod() {
      return this.$store.getters.paymentMethod
    },
    products() {
      return this.$store.getters.products
    },
    selectedProduct() {
      return this.products.find(p => p.id === this.selectedProductId)
    },
    activeProductId() {
      return this.$store.getters.productId
    },
    nextProductId() {
      return this.$store.getters.nextProductId
    }
  },
  methods: {
    formatMoney,
    async saveSubscriptionInfo() {
      this.saveLoading = true
      if(!this.paymentMethod) await this.$store.dispatch("setPaymentMethod", this.newPaymentMethod)
      await this.$store.dispatch("updateSubscription", this.selectedProductId)
      await this.$store.dispatch("getPaymentMethod")
      this.saveLoading = false
    },
    async paymentMethodDeleted() {
      this.deleteLoading = true
      await this.$store.dispatch("getPaymentMethod")
      this.deleteLoading = false
    }
  }
}
</script>
<style>
.product ul {
  list-style: none;
}

.product ul li:before {
  content: "✓";
  margin-right: 4px;
  color: rgb(0, 129, 13);
}
</style>
<style scoped>
.product {
  border-radius: 4px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  overflow: hidden;
}
.product .tag {
  position: absolute;
  right: 4px;
  top: 4px;
  padding: 2px 6px;
  border-bottom-left-radius: 4px;
}
.product .price {
  font-weight: bold;
  text-align: center;
  font-size: 26px;
}
input[type="radio"] {
  position: absolute;
  top: 8px;
  left: 6px;
  outline: none !important;
}
input[type="radio"]:checked ~ label:not(.isNext) {
  outline: 2px solid cornflowerblue
}
.isActive {
  outline: 2px solid rgb(0, 129, 13) !important;;
}
.isNext {
  outline: 2px solid #dd7a02;
}
</style>