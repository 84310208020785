<template>
  <div>
    <div v-if="value" class="card flex align-center">
      <img src="../../assets/visa-logo.png" />
      <div class="pa-6 xs8">
        <div class="number py-6">
          <div>CARD NUMBER</div>
          <div class="glass">{{ value.number }}</div>
        </div>
        <div class="expiration pt-6">
          <div>EXPIRATION DATE</div>
          <div class="flex">
            <div class="glass">{{ value.expMonth }}</div>
            <div class="mx-5 flex align-center font-weight-bold pt-2">/</div>
            <div class="glass">{{ value.expYear }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VisaCard',
  props: ["value"]
}
</script>

<style scoped>

.card {
  background-color: #488ee3;
  width: 330px;
  height: 200px;
  color: white;
  font-size: 12px;
  font-family: "Aldrich Regular", monospace;
  border-radius: 20px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  position: relative;
}
.card img {
  position: absolute;
  width: 166px;
  top: -24px;
  right: -20px;
}
.card .glass {
  background-color: rgba(255, 255, 255, 0.25);
  padding: 8px;
  letter-spacing: 2px;
  margin-top: 4px;
}
</style>
