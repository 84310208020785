<template>
  <div class="flex justify-center">
    <div style="max-width: 314px">
      <div class="flex justify-center my-4">
        <img src="../../assets/logo.png" height="32" alt="" />
      </div>
      <div><h3 class="text-center">Sign up</h3></div>
      <form>
        <Input
          v-model="email"
          label="E-mail"
          name="email"
          :disabled="loading"
        />
        <Input
            v-model="password"
            label="Password"
            name="password"
            type="password"
            :disabled="loading"
            :error="error"
            class="my-4"
        />
        <Input
            v-model="confirmPassword"
            label="Confirm password"
            name="confirmPassword"
            type="password"
            :disabled="loading"
            :error="error"
            class="my-4"
        />
        <div class="flex wrap align-center">
          <div class="xs12">
            <Button
              variant="smooth primary"
              :disabled="loading || !password || password !== confirmPassword"
              @click="signup"
              :loading="loading"
              class="mb-2 width100"
            >
              Sign up
            </Button>
          </div>
          <div class="xs12"></div>
          <div class="xs12 flex justify-center pt-5">
            Already have an account?
            <router-link class="ml-3" to="signin">Sign in</router-link>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import Input from '../common/Input.vue'
  import Button from '../common/Button.vue'

  export default {
    name: "SignUp",
    components: { Input, Button },
    data: () => ({
      email: "",
      password: "",
      confirmPassword: "",
      loading: false,
      error: ""
    }),
    methods: {
      async signup() {
        this.loading = true

        await this.$store.dispatch("signUp", {
          email: this.email,
          password: this.password
        })

        this.loading = false

        this.$router.push({ name: "confirmregistrationcheckemail" })
      }
    }
  }
</script>

<style scoped></style>
