import { createStore } from "vuex"
import { api, isArray } from "@/utils"
import createPersistedState from "vuex-persistedstate"

const userInfoState = {
  tasks: [],
  boards: [],
  timeEntries: [],
  projects: [],
  user: null,
  account: null,
  authToken: null,
  paymentMethod: null,
  products: [],
  productId: null,
  nextProductId: null,
  invoices: []
}

export default createStore({
  state () {
    return {
      ...userInfoState,
      screenWidth: 0,
      screenHeight: 0,
    }
  },
  plugins: [createPersistedState()],
  getters: {
    user (state) {
      return state.user
    },
    account (state) {
      return state.account
    },
    boards (state) {
      return (projectId) => state.boards.filter(board => board.projectId === projectId)
    },
    boardsOrderedWithTasks(state, getters) {
      return (projectId) => state.projects.find(p => p.id === projectId).boardsOrder.map(boardId => {
        const board = state.boards.find(b => b.id === boardId)
        board.tasks = getters.boardTasks(board.id)
        return board
      })
    },
    boardTasks(state) {
      return (boardId) => state.boards.find(b => b.id === boardId).tasksOrder.map(taskId => state.tasks.find(t => t.id === taskId))
    },
    archivedTasks(state) {
      return (projectId) => state.tasks.filter(task => task.projectId === projectId && task.archivedAt)
    },
    projects (state) {
      return state.projects
    },
    products (state) {
      return state.products
    },
    tasks (state) {
      return state.tasks
    },
    projectsOrdered (state) {
      if(!state.user) return []
      return state.account.projectsOrder.map(projectId => state.projects.find(p => p.id === projectId))
    },
    activeTimeEntries(state) {
      return state.timeEntries.filter(entry => !entry.end)
    },
    timeEntries(state) {
      return state.timeEntries
    },
    screenWidth(state) {
      return state.screenWidth
    },
    screenHeight(state) {
      return state.screenHeight
    },
    authToken(state) {
      return state.authToken
    },
    productId(state) {
      return state.productId
    },
    nextProductId(state) {
      return state.nextProductId
    },
    paymentMethod(state) {
      return state.paymentMethod
    },
    invoices(state) {
      return state.invoices
    }
  },
  mutations: {
    set(state, payload) {
      for(const key in payload) {
        state[key] = payload[key]
      }
    },
    updateUser(state, payload) {
      for(const key in payload) {
        state.user[key] = payload[key]
      }
    },
    updateItem(state, payload) {
      for(const key in payload) {
        for(const valueKey in payload[key]) {
          state[key][valueKey] = payload[key][valueKey]
        }
      }
    },
    setBoard(state, board) {
      const boardIndex = state.boards.findIndex(b => b.id === board.id)
      for(const key in board) {
        if(key === "id") continue
        state.boards[boardIndex][key] = board[key]
      }
    },
    addToArray(state, arrays) {
      for(const arrayKey in arrays) {
        if(isArray(arrays[arrayKey])) {
          state[arrayKey].push(...arrays[arrayKey])
        } else {
          state[arrayKey].push(arrays[arrayKey])
        }
      }
    },
    removeFromArray(state, arrays) {
      for(const arrayKey in arrays) {
        if(!isArray(arrays[arrayKey])) {
          arrays[arrayKey] = [arrays[arrayKey]]
        }
        for(const itemId of arrays[arrayKey]) {
          const index = state[arrayKey].findIndex(item => item.id === itemId)
          state[arrayKey].splice(index, 1)
        }
      }
    },
    updateItemInArray(state, arrays) {
      for(const arrayKey in arrays) {
        if(!isArray(arrays[arrayKey])) {
          arrays[arrayKey] = [arrays[arrayKey]]
        }

        for(const item of arrays[arrayKey]) {
        
          let index = -1
          for(const i in state[arrayKey]) {
            if(item.id === state[arrayKey][i].id) {
              index = i
              break
            }
          }

          for(const key in item) {
            if(key === "id") continue
            state[arrayKey][index][key] = item[key]
          }
        }
      }
    },
    updateTask(state, task) {
      const boardIndex = state.boards.findIndex(b => b.id === task.boardId)
      const taskIndex = state.boards[boardIndex].tasks.findIndex(i => i.id === task.id)
      for(const key in task) {
        if(key === "id" || key === "boardId") continue
        state.boards[boardIndex].tasks[taskIndex][key] = task[key]
      }
    },
    createTask(state, task) {
      const boardIndex = state.boards.findIndex(b => b.id === task.boardId)
      task.index = state.boards[boardIndex].tasks.length
      state.boards[boardIndex].tasks.push(task)
    }
  },
  actions: {
    reorderTasks({ commit, dispatch }, board) {
      const payload = {
        id: board.id,
        tasksOrder: board.tasks.map(task => task.id)
      }

      for(const task of board.tasks) {
        if(task.boardId !== board.id) {
          dispatch("updateTask", {
            id: task.id,
            boardId: board.id
          })
        }
      }
      
      dispatch("updateBoard", payload)
      commit("updateItemInArray", {
        boards: payload
      })
    },
    reorderBoards({ commit, dispatch }, project) {
      const payload = {
        id: project.id,
        boardsOrder: project.boards.map(board => board.id)
      }
      dispatch("updateProject", payload)
      commit("updateItemInArray", {
        projects: payload
      })
    },
    reorderProjects({commit, dispatch }, projects) {
      const payload = {
        projectsOrder: projects.map(project => project.id)
      }
      dispatch("updateUser", payload)
      commit("updateUser", payload)
    },
    async createItems({ commit, dispatch }, payload) {
      const result = {}

      for(const key in payload) {
        result[key] = []

        if(!isArray(payload[key])) payload[key] = [payload[key]]

        for(const entry of payload[key]) {
          const item = (await api({
            url: "project-tracker/" + key,
            method: "post",
            body: entry
          })).data

          console.log("createItems", item);

          result[key].push(item)
        }
      }

      commit("addToArray", result)

      dispatch("fixMissingOrder")

      return result
    },
    async updateItems({ commit, dispatch }, payload) {
      const result = {}

      for(const key in payload) {
        result[key] = []
      
        if(!isArray(payload[key])) payload[key] = [payload[key]]
        
        for(const entry of payload[key]) {
          const item = (await api({
            url: "project-tracker/" + key + '/' + entry.id,
            method: 'put',
            body: entry
          })).data

          result[key].push(item)
        }
      }
      
      commit('updateItemInArray', result)

      dispatch("fixMissingOrder")

      return result
    },
    async deleteItems({ commit }, payload) {

      const result = {}

      for(const key in payload) {
        result[key] = []
      
        if(!isArray(payload[key])) payload[key] = [payload[key]]
        
        for(const entry of payload[key]) {
          const item = (await api({
            url: "project-tracker/" + key + '/' + entry,
            method: 'delete'
          })).data

          console.log('deleteItems', item);

          result[key].push(item.id)
        }
      }
      
      commit('removeFromArray', result)
    },
    async getItems({ commit }, key) {
      const items = (await api({
        url: "project-tracker/" + key,
        method: 'get'
      })).data
      
      const commitPayload = {}
      commitPayload[key] = items
      
      commit('set', commitPayload)
    },
    async updateUser({ commit }, payload) {
      const user = (await api({
        url: 'auth/user',
        method: 'put',
        body: payload
      })).data
      commit('set', { user: user })
    },
    async createTimeEntry({ commit }, body) {
      for(const key in body) {
        if(body[key] === undefined) delete body[key]
      }
      const timeEntry = (await api({
        url: "project-tracker/timeEntries",
        method: 'post',
        body
      })).data
      commit('addToArray', {
        timeEntries: timeEntry
      })
    },
    async updateTimeEntry({ commit }, body) {
      let timeEntry
      if(body.cancelUpdate) {
        delete body.cancelUpdate
        timeEntry = body
      } else {
        timeEntry = (await api({
          url: "project-tracker/timeEntries/" + body.id,
          method: 'put',
          body
        })).data
      }
      commit('updateItemInArray', {
        timeEntries: timeEntry
      })
    },
    async deleteTimeEntry({ commit }, entryId) {
      await api({
        url: "project-tracker/timeEntries/" + entryId,
        method: 'delete'
      })
      commit("removeFromArray", { timeEntries: entryId })
    },
    async updateTask({ commit, dispatch }, body) {
      const task = (await api({
        url: "project-tracker/tasks/" + body.id,
        method: "put",
        body
      })).data
      commit("updateItemInArray", {
        tasks: task
      })
      if(body.archivedAt) {
        dispatch("fixMissingOrder")
      }
    },
    async createTask({ commit, dispatch }, body) {
      const task = (await api({
        url: "project-tracker/tasks",
        method: "post",
        body
      })).data
      commit("addToArray", {
        tasks: task
      })
      dispatch("fixMissingOrder")
    },
    async createBoard({ commit, dispatch }, body) {
      const board = (await api({
        url: "project-tracker/boards",
        method: "post",
        body
      })).data
      commit("addToArray", {
        boards: board
      })
      dispatch("fixMissingOrder")
    },
    async updateBoard({ commit }, body) {
      const board = (await api({
        url: "project-tracker/boards/" + body.id,
        method: "put",
        body
      })).data
      commit("updateItemInArray", {
        boards: board
      })
    },
    async createProject({ commit, dispatch }, body) {
      const project = (await api({
        url: "project-tracker/projects",
        method: "post",
        body
      })).data
      commit("addToArray", {
        projects: project
      })
      dispatch("fixMissingOrder")
    },
    async updateProject({ commit }, body) {
      const project = (await api({
        url: "project-tracker/projects/" + body.id,
        method: "put",
        body
      })).data
      commit("updateItemInArray", {
        projects: project
      })
    },
    async getProducts({ commit }) {
      const products = (await api({
        url: "subscription/products",
        method: "get"
      })).data
      commit("set", { products: products })
    },
    async getPaymentMethod({ commit }) {
      const paymentMethod = (await api({
        url: "subscription/paymentMethod",
        method: "get"
      })).data
      commit("set", { paymentMethod })
    },
    async getCurrentProductId({ commit }) {
      const data = (await api({
        url: "subscription/productId",
        method: "get"
      })).data
      commit("set", data)
    },
    async getInvoices({ commit }) {
      const invoices = (await api({
        url: "subscription/invoices",
        method: "get"
      })).data
      commit("set", { invoices })
    },
    async getProjects({ commit }) {
      const projects = (await api({
        url: "project-tracker/projects",
        method: "get"
      })).data
      commit("set", { projects: projects })
    },
    async getBoards({ commit }) {
      const boards = (await api({
        url: "project-tracker/boards",
        method: "get"
      })).data
      commit("set", { boards: boards })
    },
    async getTasks({ commit }) {
      const tasks = (await api({
        url: "project-tracker/tasks",
        method: "get"
      })).data
      commit("set", { tasks: tasks })
    },
    async getTimeEntries({ commit }) {
      const timeEntries = (await api({
        url: "project-tracker/timeEntries",
        method: "get"
      })).data
      commit("set", { timeEntries: timeEntries })
    },
    async getUser({ commit }) {
      const user = (await api({
        url: "auth/user",
        method: "get"
      })).data
      const account = (await api({
        url: "project-tracker/account",
        method: "get"
      })).data
      commit("set", { user, account })
    },
    async changePassword(_, passwords) {
      await api({
        url: "auth/changePassword",
        method: "post",
        body: passwords
      })
    },
    async resetPassword(_, email) {
      await api({
        url: "auth/resetPassword",
        method: "post",
        body: { email }
      })
    },
    async resetPasswordChangePassword(_, payload) {
      await api({
        url: "auth/resetPasswordChangePassword",
        method: "post",
        body: payload
      })
    },
    async fixMissingOrder({ state, getters, commit }) {
      // This should all happen in the backend.
      let updateUser = false
      if(!getters.account.projectsOrder) getters.account.projectsOrder = []
      for (const project of getters.projects) {
        if (getters.account.projectsOrder.indexOf(project.id) < 0) {
          getters.account.projectsOrder.push(project.id)
          updateUser = true
        }
        let updateProject = false
        if (!project.boardsOrder) project.boardsOrder = []
        for (const board of getters.boards(project.id)) {
          if (project.boardsOrder.indexOf(board.id) < 0) {
            project.boardsOrder.push(board.id)
            updateProject = true
          }
          let updateBoard = false
          if (!board.tasksOrder) board.tasksOrder = []
          for (const task of state.tasks) {
            if (task.boardId === board.id && !task.archivedAt && board.tasksOrder.indexOf(task.id) < 0) {
              board.tasksOrder.push(task.id)
              updateBoard = true
            } else if(task.archivedAt && board.tasksOrder.indexOf(task.id) > -1) {
              board.tasksOrder.splice(board.tasksOrder.indexOf(task.id), 1)
            }
          }
          if(updateBoard) {
            const newBoard = (await api({
              url: "project-tracker/boards/" + board.id,
              method: "put",
              body: {
                tasksOrder: board.tasksOrder
              }
            })).data
            commit("updateItemInArray", {
              boards: newBoard
            })
          }
        }
        if(updateProject) {
          const newProject = (await api({
            url: "project-tracker/projects/" + project.id,
            method: "put",
            body: {
              boardsOrder: project.boardsOrder
            }
          })).data
          commit("updateItemInArray", {
            projects: newProject
          })
        }
      }
      if(updateUser) {
        const account = (await api({
          url: "project-tracker/accounts/" + getters.account.id,
          method: "put",
          body: {
            projectsOrder: getters.account.projectsOrder
          }
        })).data
        commit("updateItem", { account: { projectsOrder: account.projectsOrder }})
      }
    },
    async signIn({ commit, dispatch }, emailAndPassword) {
      const response = (await api({
        url: "auth/login",
        method: "post",
        body: emailAndPassword
      })).data

      commit("set", {
        authToken: response.token,
        user: response.user,
      })

      await dispatch("init")
    },
    signOut({ commit }) {
      commit("set", userInfoState)
    },
    async init({ dispatch, getters }) {
      if(getters.user) {

        await dispatch("getUser")
        await dispatch("getProjects")
        await dispatch("getPaymentMethod")
        await Promise.all([
            dispatch("getBoards"),
            dispatch("getTasks"),
            dispatch("getTimeEntries"),
            dispatch("getProducts"),
            dispatch("getCurrentProductId"),
            dispatch("getInvoices")
        ])
      }

      // await dispatch("fixMissingOrder")
    },
    async signup({ dispatch, commit }, emailAndPassword) {

      const response = (await api({
        url: "auth/register",
        method: "post",
        body: emailAndPassword
      })).data

      commit("set", {
        authToken: response.token,
        user: response.user
      })

      await dispatch("init")
    },
    async confirmRegistration(_, token) {
      await api({
        url: "auth/confirmRegistration",
        method: "post",
        body: { token }
      })
    },
    async updateSubscription({ commit }, nextProductId) {
      (await api({
        url: "subscription/update",
        method: "post",
        body: { productId: nextProductId }
      })).data
      commit("set", {
        nextProductId
      })
    },
    async setPaymentMethod({ commit }, paymentInfo) {
      const paymentMethod = (await api({
        url: "subscription/paymentMethod",
        method: "post",
        body: paymentInfo
      })).data
      commit("set", {
        paymentMethod
      })
    },
    async removePaymentMethod({ commit }) {
      (await api({
        url: "subscription/paymentMethod",
        method: "delete"
      })).data
      commit("set", {
        paymentMethod: null
      })
    },
    set({ commit }, payload) {
      commit("set", payload)
    },
    updateItemInArray({ commit }, arrays) {
      commit("updateItemInArray", arrays)
    },
    addToArray({ commit }, arrays) {
      commit("addToArray", arrays)
    },
    removeFromArray({ commit }, arrays) {
      commit("removeFromArray", arrays)
    },
  }
})